<template>
  <div>
    <v-stepper
      v-model="step"
      alt-labels
      color="primary"
      selected-class="selected"
      v-bind="$attrs"
    >
      <v-stepper-header>
        <StepperItem
          title="Share feedback"
          :item-step="1"
          :current-step="step"
        />
        <v-col class="px-0">
          <v-progress-linear
            :model-value="step === 1 ? 50 : 100"
            :color="step > 1 ? 'primary-darken-2' : 'info-darken-1'"
            rounded-bar
            rounded="shaped"
            aria-label="Wizard progress 1st phase"
          />
        </v-col>
        <StepperItem title="Add details" :item-step="2" :current-step="step" />
        <v-col class="px-0">
          <v-progress-linear
            :model-value="step === 1 ? 0 : step === 2 ? 50 : 100"
            :color="step > 2 ? 'primary-darken-1' : 'info-darken-1'"
            rounded-bar
            rounded="shaped"
            aria-label="Wizard progress 2nd phase"
          />
        </v-col>
        <StepperItem
          title="Confirm conditions"
          :item-step="3"
          :current-step="step"
        />
      </v-stepper-header>
      <v-stepper-window>
        <FirstStep
          v-if="step === 1"
          ref="FirstStepRef"
          v-model:feedbackType="formAnswers.feedbackType"
          v-model:appType="formAnswers.app.type"
          v-model:description="formAnswers.description"
          v-model:files="filesToUpload"
          :is-uploading-file="isUploadingFile"
          :file-rules="fileRules"
        />
        <SecondStep
          v-else-if="step === 2"
          ref="SecondStepRef"
          v-model:app="formAnswers.app"
          v-model:feedbackUrl="formAnswers.feedbackUrl"
          v-model:feedbackWorth="formAnswers.feedbackWorth"
          v-model:device="formAnswers.device"
          :app-type="formAnswers.app.type"
          :feedback-type="formAnswers.feedbackType"
        />
        <LastStep
          v-else-if="step === 3"
          ref="LastStepRef"
          v-model:user="formAnswers.user"
          v-model:isAnonymous="formAnswers.isAnonymous"
          v-model:acceptTermsOfService="formAnswers.acceptTermsOfService"
        />
        <CoinsFlying v-else-if="step === 4" />
      </v-stepper-window>

      <template #actions="{ prev, next }">
        <v-container>
          <v-row class="pb-1">
            <v-col v-if="step > 1 && step < 4">
              <v-btn
                class="w-100"
                :prepend-icon="mdiArrowLeft"
                size="large"
                @click="prev"
              >
                Previous
              </v-btn>
            </v-col>
            <v-col sm="6" xs="12" class="ms-auto me-0 text-end">
              <v-btn
                v-if="step < 4"
                :append-icon="mdiArrowRight"
                color="info"
                class="w-100"
                :loading="isSubmitting"
                size="large"
                @click="onNext(next)"
              >
                {{ step < 3 ? 'Next' : 'Submit' }}
              </v-btn>
              <v-btn
                v-else-if="step === 4"
                color="info"
                class="w-100"
                size="large"
                @click="resetForm"
              >
                New feedback
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-stepper>
    <Snackbar
      v-model="showSnackbar"
      :content="snackbarContent"
      :type="snackbarType"
    />
  </div>
</template>

<script setup>
import { mdiArrowRight, mdiArrowLeft } from '@mdi/js';

import {
  MAX_FILE_SIZE_MB,
  MAX_FILES_ALLOWED,
  supportedFileTypes,
  feedbackFormAnswers,
} from '@/constants/feedback';
import useSubmitFeedbackForm from '@/components/Feedback/useSubmitFeedbackForm';
import StepperItem from '@/components/Feedback/StepperItem.vue';
import FirstStep from '@/components/Feedback/external/steps/FirstStep.vue';
import {
  fileSizeLessThanXMb,
  isFileTypeSupported,
  isWithinMaxFilesLimit,
} from '@/utils/validations';

const SecondStep = defineAsyncComponent(
  () => import('@/components/Feedback/external/steps/SecondStep.vue'),
);
const LastStep = defineAsyncComponent(
  () => import('@/components/Feedback/steps/LastStep.vue'),
);
const CoinsFlying = defineAsyncComponent(
  () => import('@/components/Feedback/external/CoinsFlying.vue'),
);

defineOptions({
  name: `ExternalFeedbackStepper`,
});

const fileRules = [
  fileSizeLessThanXMb(MAX_FILE_SIZE_MB),
  isFileTypeSupported(supportedFileTypes),
  isWithinMaxFilesLimit(MAX_FILES_ALLOWED),
];

const step = ref(1);

const formAnswers = ref(feedbackFormAnswers());

const FirstStepRef = ref();
const SecondStepRef = ref();
const LastStepRef = ref();

const onNext = async (nextFn) => {
  let isValid;
  switch (step.value) {
    case 1:
      isValid = await FirstStepRef.value?.validateForm();
      if (isValid) {
        return nextFn();
      }
      break;
    case 2:
      isValid = await SecondStepRef.value?.validateForm();
      if (isValid) {
        return nextFn();
      }
      break;
    case 3:
      isValid = await LastStepRef.value?.validateForm();
      if (isValid) {
        return onSubmitForm();
      }
      break;
    default:
      break;
  }
  showError(`You have some errors, check the fields and fix them`);
};

const { showSnackbar, snackbarType, snackbarContent, showError } =
  useSnackbar();

const {
  filesToUpload,
  isUploadingFile,
  associateUploadedFileWithFeedbackId,
  uploadedFilesIds,
} = useFileUpload({ fileRules, showError });

const resetForm = () => {
  formAnswers.value = feedbackFormAnswers();
  step.value = 1;
  // Emptying it since it's new form
  // And also so we don't send a delete request
  uploadedFilesIds.value = [];
  filesToUpload.value = [];
};

const { submitForm, isSubmitting } = useSubmitFeedbackForm({
  showError,
  associateUploadedFileWithFeedbackId,
});

const onSubmitForm = async () => {
  const { user, ...rest } = formAnswers.value;
  const submission = await submitForm({
    ...user,
    ...rest,
  });
  if (submission) {
    step.value = 4;
  }
};
</script>
